export const initialCountsSectors = [
    {
        name: "All Projects",
        count: 0,
    },
    {
        name: "Tourism",
        count: 0,
    },
    {
        name: "Social Media",
        count: 0,
    },
    {
        name: "Software",
        count: 0,
    },
    {
        name: "Manufacturing",
        count: 0,
    },
    {
        name: "Construction & Real Estate",
        count: 0,
    },
    {
        name: "eGovernment",
        count: 0,
    },
    {
        name: "Automotive",
        count: 0,
    },
    {
        name: "Research & Development",
        count: 0,
    },
    {
        name: "Health & Medical",
        count: 0,
    },
    {
        name: "Transport",
        count: 0,
    },
    {
        name: "Computer Equipment Services",
        count: 0,
    },
    {
        name: "Entertainment",
        count: 0,
    },
    {
        name: "Media",
        count: 0,
    },
    {
        name: "Education",
        count: 0,
    },
    {
        name: "Finance & Banking",
        count: 0,
    },
    {
        name: "E-Commerce & Retail",
        count: 0,
    },
    {
        name: "Telecommunications",
        count: 0,
    },
    {
        name: "Philanthropy and Impact Investment",
        count: 0,
    },
    {
        name: "DeepTech and AI",
        count: 0,
    },
    {
        name: "Life Sciences and HealthTech",
        count: 0,
    },

];
