import React, { useState } from "react";

import { Typography, List, Button, Tooltip, Modal } from "antd";
import Icon, {
  FileTextOutlined,
  DownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  DownOutlined,
  UpOutlined
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

import {
  askPermissionToDocument,
  deleteAnyDocument,
  signAnyDocuments,
} from "../../redux/action";
import { useSelector } from "react-redux";

import { SuccessSvg } from "../../../../assets/images/successSvg";
import { BASE_URL, dataRoomNotificationApi } from "../../../../api";
import IframeComponent from "../../components/IframeComponent";

const { Text } = Typography;

const DocumentsList = ({
  documents,
  setEditDoc,
  dealId,
  imagesPath = "",
  videoLink,
  projectName,
}) => {
  const dispatch = useDispatch();
  const [ showIframeFile, setShowIframeFile ] = useState("")

  const { signed, pending } = useSelector((state) => state?.dealDetails);

  const isLink = (input) => {
    return typeof(item) === "object" ? input?.link : input
  }
  const handleSign = (id) => {
    const formData = new FormData();
    formData.append("document_id", id);

    dispatch(signAnyDocuments(formData));
  };

  const handleEyeClick = (file) => {
    const url = BASE_URL + imagesPath + file.link;
    if (videoLink) {
      window.open(videoLink);
    } else {
      window.open(url, "_blank");
    }
  };

  if (videoLink) {
    documents.push(videoLink);
  }


  
  return (
    <List
      itemLayout="horizontal"
      dataSource={documents}
      renderItem={(item) => {
        const actions = [];

        if (item?.url) {
          actions.push(
            <a
              href={item.url}
              target="_blank"
              style={{
                fontSize: "30px",
                color: "#111827",
              }}
              rel="noreferrer"
            >
              <DownloadOutlined onClick={() => console.log(item)} />
            </a>
          );
        }

        if (!item?.url && !item.is_permission_request) {
          actions.push(
            <Tooltip title="View File">
              <EyeOutlined
                style={{ fontSize: "22px" }}
                onClick={() => handleEyeClick(item)}
              />
            </Tooltip>
          );
        }
          actions.push(
            (isLink(item) === showIframeFile) ?
            <Tooltip title="Hide Document">
              <UpOutlined
                style={{ fontSize: "22px" }}
                onClick={() => setShowIframeFile((prev) => prev === showIframeFile ? null : isLink(item))}
              />
            </Tooltip>
            : item?.link ?
            <Tooltip title="View here">
              <DownOutlined
                style={{ fontSize: "22px" }}
                onClick={() => setShowIframeFile(isLink(item))}
              />
            </Tooltip>
            : null
          );

        if (item.is_permission_request) {
          actions.push(<Text style={{ color: "#16b364" }}>Requested</Text>);
        }

        if (item.is_delete) {
          actions.push(
            <EditOutlined
              style={{ fontSize: "22px" }}
              onClick={() => {
                setEditDoc(item);
              }}
            />
          );

          actions.push(
            <DeleteOutlined
              style={{ fontSize: "22px" }}
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure you want to delete this document ?",
                  onOk() {
                    const formData = new FormData();
                    if (Object.keys(item).includes("document_id")) {
                      formData.append("document_id", item?.document_id);
                    }

                    if (Object.keys(item).includes("add_document_id")) {
                      formData.append("document_id", item?.add_document_id);
                    }

                    dispatch(deleteAnyDocument({ formData, dealId }));
                  },
                  onCancel() {},
                });
              }}
            />
          );
        }

        if (item.is_edit_permission) {
          actions.push(
            <EditOutlined
              style={{ fontSize: "22px" }}
              onClick={() => {
                setEditDoc(item);
              }}
            />
          );
        }

        if (!signed.includes(item?.add_document_id) && item?.sign_flag) {
          if (item?.sign_status === "not signed") {
            actions.push(
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => handleSign(item?.add_document_id)}
                loading={pending === item.document_id}
              >
                Sign
              </Button>
            );
          } else {
            actions.push(<Icon component={SuccessSvg} />);
          }
        }

        return (
          <>
          <List.Item
          actions={actions}
          // onClick={() => handleEyeClick(item)}
          style={{ cursor: "pointer" }}
        >
          {
            item?.link &&
            <List.Item.Meta
            style={{ width: "auto" }}
            avatar={
              <FileTextOutlined
                onClick={() => handleEyeClick(item)}
                style={{ fontSize: "2.5rem" }}
              />
            }
            title={
              <Text
                onClick={() => handleEyeClick(item)}
                style={{ cursor: "pointer" }}
                className="lable ovr-wrp"
              >
                {item.name || "Watch " + projectName + " Video"}
              </Text>
            }
          />
        }
        </List.Item>
        {
          (isLink(item) === showIframeFile) &&
          <IframeComponent
          source={`${BASE_URL}${imagesPath}${item?.link}`} 
          title={projectName} />
        }
        </>
        );
      }}
    />
  );
};

export default DocumentsList;
