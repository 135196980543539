import { createSlice } from '@reduxjs/toolkit';

const loadCartFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('cartItems');
        return serializedState ? JSON.parse(serializedState) : { items: [], totalAmount: 0 };
    } catch (e) {
        console.warn("Could not load cart from local storage", e);
        return { items: [], totalAmount: 0 };
    }
};

const saveCartToLocalStorage = (cart) => {
    try {
        const serializedState = JSON.stringify(cart);
        localStorage.setItem('cartItems', serializedState);
    } catch (e) {
        console.warn("Could not save cart to local storage", e);
    }
};

const initialState = loadCartFromLocalStorage();

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem(state, action) {
            const newItem = action.payload;
            const itemExist = state.items.find((i) => i.title === newItem.title);
            if (!itemExist) {
                state.items.push(newItem);
                state.totalAmount += newItem.price;
            }
            saveCartToLocalStorage(state);
        },
        removeItem(state, action) {
            const deleteItem = action.payload;
            state.items = state.items.filter(item => item.title !== deleteItem.title);
            state.totalAmount -= deleteItem.price;
            saveCartToLocalStorage(state);
        },
    },
});

export const { addItem, removeItem } = cartSlice.actions;
export default cartSlice.reducer;
