import "./App.less";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { initializeTagManager } from "./gtm";

import "antd/dist/antd.css";
import "./assets/styles/main.less";
import "./assets/styles/responsive.less";

import AppRoutes from "./routes/AppRoutes";
import { getAllChatAction } from "./pages/chatPage/redux/action";
import { getUser } from "./Utils/isAuth";

function App() {
  const user = getUser();
  const dispatch = useDispatch();

  useEffect(() => {
    initializeTagManager();
    let intervalId = "";

    if (user) {
      dispatch(getAllChatAction());
      intervalId = setInterval(() => {
        dispatch(getAllChatAction());
      }, 300000);
    } else if (intervalId !== "") {
      clearInterval(intervalId);
    }

    return () => (intervalId !== "" ? clearInterval(intervalId) : null);
  }, [dispatch, user]);

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
