import "./iframeStyle.css";

const IframeComponent = ({title, source}) => {
    return (
        <iframe
            className='loading-style'
            title={title}
            style={{
                width: "100%",
                height: "650px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
            src={
                source ? source : "" 
            } 
        />
    )
}

export default IframeComponent