import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Affix, Spin, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { LoadingOutlined, MessageOutlined } from "@ant-design/icons";

import UserSider from "../../components/UserSider";
import MobileHeader from "../../components/Common/MobileHeader";
import Sidenav from "./Sidenav";
import Header from "./Header";

const { Header: AntHeader, Content, Sider } = Layout;

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

function Main({ children, hideBlock = false }) {
  const [visible, setVisible] = useState(false);
  const loading = useSelector((state) => state.userDetail.loading);
  const navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1210px)",
  });

  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  const checkPath = pathname.includes("dealdetails")
    ? "dealpage"
    : "organizer page";

  return (
    <>
      <Layout className="layout-dashboard">
        {loading && (
          <div className="spinner_background">
            <Spin className="spinner" indicator={antIcon} />
          </div>
        )}
        {isDesktopOrLaptop && (
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            width={300}
            trigger={null}
            theme="light"
            className="sider-primary ant-layout-sider-primary"
          >
            <Sidenav color={sidenavColor} />
          </Sider>
        )}
        <Layout hasSider={false}>
          {!isDesktopOrLaptop && (
            <Affix className="header-mob">
              <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <MobileHeader />
              </AntHeader>
            </Affix>
          )}

          <AntHeader className={`${hideBlock ? "hide-block" : ""}`}>
            <Header
              onPress={openDrawer}
              name={pathname}
              subName={pathname}
              handleSidenavColor={handleSidenavColor}
              handleSidenavType={handleSidenavType}
              handleFixedNavbar={handleFixedNavbar}
            />
          </AntHeader>

          <Content
            className={`${
              pathname === "chat" ? "chat-content" : "content-ant"
            }`}
          >
            {children}
          </Content>
          <UserSider checkPath={checkPath} />
        </Layout>
      </Layout>
      {/* {!pathname.includes("chat") && (
        <Tooltip placement="top" title="Support">
          <div
            className="support-chat"
            onClick={() => navigate("/chat?openSupport=true")}
          >
            <MessageOutlined style={{ fontSize: "30px" }} />
          </div>
        </Tooltip>
      )} */}
    </>
  );
}

export default Main;
