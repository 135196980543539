import React, { useState } from 'react';
import { Card, Row, Col, Button, Typography, Space, message } from 'antd';
import { RocketTwoTone, DollarOutlined } from '@ant-design/icons';
import "./style.css"
import { updateUsersData } from '../../api';
import { getUser } from '../../Utils/isAuth';
import { useNavigate } from 'react-router-dom';
import wrongPageIcon from "../../assets/images/wrongIcon.png"



const { Title, Text } = Typography;

const RoleSelect = () => {
    const [selectedRole, setSelectedRole] = useState(null);
    const {_id, roles} = getUser()
    const navigate = useNavigate()

    const handleSelectionComplete = () => {
        updateUsersData({
            roles: selectedRole,
            _id
        })
            .then((res) => {
                // Redirect
                message.success(res.data.message);
                const userStr = localStorage.getItem("user");
                if (userStr) {
                    const user = JSON.parse(userStr);
                    user.roles = selectedRole; // Update the roles in user object
                    localStorage.setItem("user", JSON.stringify(user));
                }
                navigate("/")
            })
            .catch((err) => {
                message.error(
                    err.response?.data?.message ||
                    "Something went wrong, Please try again"
                );
            });
    };

    const color = `#64748B`

    return (
        <div className='role-selector-container'>
            {
                roles === "Basic" ? 
                <div style={{ padding: '50px', textAlign: 'center', margin: "auto" }}>
                    <Title level={2}>Select Your Role</Title>
                    <Text type="secondary">Please select your role to proceed:</Text>
                    <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                        <Col xs={24} sm={12} className='card-select'>
                            <Card
                                hoverable
                                onClick={() => setSelectedRole('Startup')}
                                style={{
                                    borderColor: selectedRole === 'Startup' ? '#1890ff' : '#f0f0f0',
                                }}
                            >
                                <Space direction="vertical" align="center" size="large">
                                    <RocketTwoTone style={{ fontSize: '48px', color: '#1890ff' }} />
                                    <Title level={4}>Startup</Title>
                                    <Text style={{ color }}>
                                        If you are a founder or team member of a startup looking to create and manage your projects on our platform.
                                    </Text>
                                </Space>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} className='card-select'>
                            <Card
                                hoverable
                                onClick={() => setSelectedRole('Investor')}
                                style={{
                                    borderColor: selectedRole === 'Investor' ? '#1890ff' : '#f0f0f0',
                                }}
                            >
                                <Space direction="vertical" align="center" size="large">
                                    <DollarOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
                                    <Title level={4}>Investor</Title>
                                    <Text style={{ color }}>
                                        If you are an investor looking to explore and invest in innovative projects.</Text>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                    <Button
                        type="primary"
                        size="large"
                        style={{ marginTop: '20px' }}
                        disabled={!selectedRole}
                        onClick={handleSelectionComplete}
                    >
                        Continue
                    </Button>
                </div> : 
                <div className="wrong-error">
                    <img src={wrongPageIcon} alt='wrong-page' width={200} height={200} />
                    <h1>You have visited the wrong page</h1>
                    <Button type='primary' onClick={()=> navigate("/")}>Go back home</Button>
                </div>
            }
        </div>
    );
};

export default RoleSelect;
