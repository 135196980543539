import React from "react";
import { Avatar, Card, Tag, Row, Col, Dropdown, Menu, message } from "antd";
import Icon from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { DropdownSvg } from "../../assets/images/dropdownSvg";
import "antd/dist/antd.css";
import "./style.scss";
import { getFirstLater, getImageURL } from "../../Utils";
import moment from "moment";
import {
  deleteProjectTableData,
  editProjectTableData,
  publishDeal,
  updateDeal,
} from "../../api/index.js";
import { archiveProjectTableData } from "../../api/index.js";
import { getToken, getUser } from "../../Utils/isAuth";

const dotColor = (name) => {
  let outer = "";
  let inner = "";
  if (name === "Not Filled") {
    outer = "#FEF3C7";
    inner = "#FBBF24";
  } else {
    outer = "#D1FAE5";
    inner = "#34D399";
  }
  return {
    outer: {
      backgroundColor: outer,
    },
    inner: {
      backgroundColor: inner,
    },
  };
};

const statusStyle = (status) => {
  let backgroundColor = "";
  let textColor = "";
  if (status === "Invited") {
    textColor = "#92400E";
    backgroundColor = "#FEF3C7";
  } else if (status === "Active") {
    textColor = "#027A48";
    backgroundColor = "#ECFDF3";
  } else if (status === "Disabled") {
    textColor = "#991B1B";
    backgroundColor = "#FEF2F2";
  }

  return {
    color: textColor,
    backgroundColor: backgroundColor,
  };
};

const ml8 = { marginLeft: "8px" };

const DropDownExt = ({ record, onConfigure }) => {
  const menuItems = [
    {
      label: (
        <div onClick={() => onConfigure(record)} className="pointer">
          Configure
        </div>
      ),
      key: 1,
    },
  ];

  return (
    <Dropdown
      overlay={<Menu className="dropdown-row" items={menuItems} />}
      trigger={["click"]}
      placement="bottomLeft"
    >
      <Icon component={DropdownSvg} className="dropdown-menu-button" />
    </Dropdown>
  );
};

export const usersDeviceColumns = (onConfigure) => [
  {
    render: (text, record) => {
      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Row>
                <Col sm={24} className="center-vertical">
                  <Avatar
                    shape="round"
                    size={50}
                    src={getImageURL(record?.img_url || "")}
                    className={`${
                      !record?.img_url ? "shape-avatar-letter" : ""
                    } shape-avatar`}
                  >
                    {getFirstLater(record?.email || "")}
                  </Avatar>
                  <div className="avatar-info">
                    <Title level={5}>
                      {record?.firstName} {record?.lastName}
                    </Title>
                    <p>{record?.email}</p>
                  </div>
                </Col>
              </Row>
            </Avatar.Group>
          }
          extra={
            <div className="center-vertical">
              <DropDownExt record={record} onConfigure={onConfigure} />
            </div>
          }
        >
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Role</p>
              <Title level={5}>{record?.roles}</Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Last Modified</p>
              <Title level={5}>
                {moment(record?.updated_at).format("DD-MM-YYYY HH:MM")}
              </Title>
            </div>
          </Card.Grid>
        </Card>
      );
    },
  },
];

export const usersColumns = (onConfigure) => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name"
      );

      return (
        <div className="center-vertical">
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={ml8} />
            ) : (
              <ArrowDownOutlined style={ml8} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            shape="round"
            size={40}
            src={getImageURL(record?.img_url || "")}
            className={`${
              !record?.img_url ? "shape-avatar-letter" : ""
            } shape-avatar`}
          >
            {getFirstLater(record?.email || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>
              {record?.firstName} {record?.lastName}
            </Title>
            <p>{record?.email}</p>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "role"
      );

      return (
        <div className="center-vertical">
          Role
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={ml8} />
            ) : (
              <ArrowDownOutlined style={ml8} />
            )
          ) : null}
        </div>
      );
    },
    dataIndex: "role",
    key: "roles",
    sorter: (a, b) => a.roles.localeCompare(b.roles),
    render: (text, record) => {
      return (
        <div className="author-info">
          <Title level={5}>
            {record?.roles === "Organisation" ? "User" : record?.roles}
          </Title>
        </div>
      );
    },
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "lastModify"
      );

      return (
        <div className="center-vertical">
          Last Modified
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={ml8} />
            ) : (
              <ArrowDownOutlined style={ml8} />
            )
          ) : null}
        </div>
      );
    },
    dataIndex: "lastModify",
    key: "updated_at",
    sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
    render: (text, record) => {
      return (
        <div className="author-info">
          <Title level={5}>
            {moment(record?.updated_at).format("DD-MM-YYYY HH:MM")}
          </Title>
        </div>
      );
    },
  },
  {
    render: (text, record) => {
      return <DropDownExt record={record} onConfigure={onConfigure} />;
    },
  },
];

const ProjectDropDownExt = ({
  record,
  fetchData,
  navigateEdit,
  onShareAccess,
}) => {
  const user = getUser();
  const token = getToken();
  const onArchiveProfile = () => {
    try{
      // archiveProjectTableData(record._id).then(fetchData)
      const response = archiveProjectTableData(record?._id).then(fetchData);
      message.success(`Project has been archived`)
    }catch(err){
      message.error(`Something went wrong: ${err?.response?.data?.message}`)
    }
  }

  const onDeleteProfile = () => {
    // deleteProjectTableData(record?._id).then(fetchData);
    try{
      const response = deleteProjectTableData(record?._id).then(fetchData);
      message.success(`Deleted Succesfully`)
    }catch(err){
      message.error(`Something went wrong: ${err?.response?.data?.message}`)
    }
  }
  const onPublishUnpublishClick = () =>{ 
    try{
      const response = publishDeal(token, {status:"Active", id:record?._id}).then(fetchData) 
      message.success(`Status has been changed`)
    }catch(err){
      message.error(`Something went wrong: ${err?.response?.data?.message}`)
    }
  }


  const menuItems = [
    {
      label: (
        <div onClick={() => navigateEdit(record?._id)} className="pointer">
          Edit
        </div>
      ),
      key: 3,
    },
    {
      label: (
        <div onClick={() => navigateEdit(record?._id)} className="pointer">
          View Invest requests
        </div>
      ),
      key: 31,
    },
  ];

  if (record?.owner?._id === user?._id) {
    menuItems.push({
      label: (
        <div onClick={() => onShareAccess(record)} className="pointer">
          Share Access
        </div>
      ),
      key: 1,
    });
    menuItems.push({
      label: (
        <div onClick={onDeleteProfile} className="pointer">
          Delete
        </div>
      ),
      key: 2,
    });
  }
  // User can archive it only if it's active
  if ((record?.status === "Active" ) && record?.owner?._id === user?._id) {
    menuItems.push({
      label: (
        <div onClick={onArchiveProfile} className="pointer">
          Archive
        </div>
      ),
      key: 4,
    });
  }
  if ((record?.status === "Archived" ) && record?.owner?._id === user?._id) {
    menuItems.push({
      label: (
        <div onClick={onPublishUnpublishClick} className="pointer">
          Publish 
        </div>
      ),
      key: 5,
    });
  }

  return (
    <Dropdown
      overlay={<Menu className="dropdown-row" items={menuItems} />}
      trigger={["click"]}
      placement="bottomLeft"
    >
      <Icon component={DropdownSvg} className="dropdown-menu-button" />
    </Dropdown>
  );
};

export const projectsColumns = (fetchData, navigateEdit, onShareAccess) => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name"
      );

      return (
        <div className="center-vertical">
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={ml8} />
            ) : (
              <ArrowDownOutlined style={ml8} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) => a.projectName.localeCompare(b.projectName),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            shape="round"
            size={40}
            src={getImageURL(record?.imagesPath || "")}
            className={`${
              !record?.imagesPath ? "shape-avatar-letter" : ""
            } shape-avatar`}
          >
            {getFirstLater(record?.projectName || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>{record?.projectName}</Title>
            <p>{record?.owner?.email}</p>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: "Target amount",
    dataIndex: "goalAmount",
    key: "target amount",
    render: (text, record) => {
      return (
        <div className="author-info">
          <Title level={5}>  { record?.goalAmount ? (Number(record?.goalAmount).toLocaleString("en-US", {style:"currency", currency:"USD"})) : "Not set" }</Title>
        </div>
      );
    },
  },
  {
    title: "Invest requests",
    dataIndex: "investorsRequestsCount",
    key: "Request",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: (
      <div className="ver">
        Status <QuestionCircleOutlined style={ml8} />
      </div>
    ),
    dataIndex: "status",
    key: "status",
    render: (status) => {
      return (
        <Tag className="tag_text" color={statusStyle(status).backgroundColor}>
          <span
            className="status-txt"
            style={{
              color: statusStyle(status).color,
            }}
          >
            &#9679;&nbsp;{status}
          </span>
        </Tag>
      );
    },
  },

  {
    render: (text, record) => {
      return (
        <ProjectDropDownExt
          record={record}
          fetchData={fetchData}
          navigateEdit={navigateEdit}
          onShareAccess={onShareAccess}
        />
      );
    },
  },
];

export const myProjectsDeviceColumns = (
  fetchData,
  navigateEdit,
  onShareAccess
) => [
  {
    render: (text, record) => {
      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Row>
                <Col sm={24} className="center-vertical">
                  <Avatar
                    shape="round"
                    size={50}
                    src={getImageURL(record?.imagesPath || "")}
                    className={`${
                      !record?.imagesPath ? "shape-avatar-letter" : ""
                    } shape-avatar`}
                  >
                    {getFirstLater(record?.projectName || "")}
                  </Avatar>
                  <div className="avatar-info">
                    <Title level={5}>{record?.projectName}</Title>
                    <p>{record?.owner?.email}</p>
                  </div>
                </Col>
              </Row>
            </Avatar.Group>
          }
          extra={
            <div className="center-vertical">
              <Tag
                className="tag_text"
                color={statusStyle(record?.status).backgroundColor}
              >
                <span
                  className="status-txt"
                  style={{
                    color: statusStyle(record?.status).color,
                  }}
                >
                  &#9679;&nbsp;{record?.status}
                </span>
              </Tag>
              <ProjectDropDownExt
                record={record}
                fetchData={fetchData}
                navigateEdit={navigateEdit}
                onShareAccess={onShareAccess}
              />
            </div>
          }
        >
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Last activity</p>
              <Title level={5} style={{ textAlign: "right" }}>
                {moment(record?.updatedAt).format("DD/MM/YYYY")}
              </Title>
            </div>
          </Card.Grid>
        </Card>
      );
    },
  },
];
