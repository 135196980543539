import axios from "axios";
import queryString from "qs";
import {
  getToken,
  setUserSession,
  getRefreshToken,
  removeUserSession,
} from "../Utils/isAuth";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const BASE_SOCKET_URL =
  process.env.NODE_ENV === "development"
    ? "ws://spv-dev.dkv.global:8001/ws/chat/"
    : `ws://${window.location.hostname}:8001/ws/chat/`;

const request = axios.create({
  baseURL: BASE_URL,
});

const api = (options = {}) => {
  let config = {
    baseURL: "https://spv-dev.dkv.global:8000",
    ...options,
    paramsSerializer: (params) =>
      queryString.stringify(params, { arrayFormat: "repeat" }),
    headers: {
      Accept: "*/*",
      ...options.headers,
    },
  };
  return request(config);
};

axios.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (
      token &&
      !config.url.includes("user/login") &&
      !config.url.includes("user/register") &&
      !config?.headers?.Authorization
    ) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    if (config?._retry) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    const originalRequest = error.config;

    if (
      error?.response?.status === 401 &&
      originalRequest.url === `${BASE_URL}/api/user/token/`
    ) {
      if (window.location.pathname !== "/register") {
        window.location.href = window.location.origin;
      }

      removeUserSession();
      return Promise.reject(error);
    }

    if (
      error.response.status === 400 &&
      originalRequest.url === `${BASE_URL}/api/user/token/`
    ) {
      if (window.location.pathname !== "/register") {
        window.location.href = window.location.origin;
      }

      removeUserSession();
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();
      ////////
      return axios
        .post(`${BASE_URL}/api/user/token/`, {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.access;
            setUserSession(res.data.access);

            return axios(originalRequest);
          }
        });
    }

    return Promise.reject(error);
  }
);

function globalSearchByName(queryParams) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/general-search/?search=${queryParams}`,
  });
}
function deleteDocs(token, files) {
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/download-upload-docs/`,
    data: files,
  });
}

function signUp(authParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/auth/registration`,
    data: authParams,
  });
}

function postsubscribe(authParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/email-subscribe/`,
    data: authParams,
  });
}
function signIn(authParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/auth/login/`,
    data: authParams,
  });
}

function getProject({ headers, id }) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/project/getProject?id=${id}`,
    headers: { headers },
  });
}

function updateUserApi(authParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/user/changeUser/`,
    data: authParams,
  });
}

function updatePasswordApi({ headers, data }) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/user/changePassword/`,
    data: data,
    headers: { headers },
  });
}

function getUserApi({ headers, id }) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/user/getUser`,
    headers: { headers },
  });
}

function longevityLogin(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/validate-token-longevity/`,
    data: data,
  });
}

function selectAccount(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/account-type/`,
    data: selectionParams,
    headers: { "Content-Type": "application/json" },
  });
}

function addinvestorType(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/sub-account-type/ `,
    data: selectionParams,
  });
}

function addBankAccount(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/company-bank-account/`,
    data: selectionParams,
  });
}

function forgotPassword(selectionParams) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/auth/sendEmailForResetPassword`,
    params: selectionParams,
  });
}
function verifyForgotPassword(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/verify-reset-password-email/`,
    data: selectionParams,
  });
}

function changePassword(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/auth/resetPassword`,
    data: selectionParams,
  });
}

function getCurrentPassword() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/changepassword/`,
  });
}

function resendSignupVerify(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/auth/sendConfirmEmail`,
    params,
  });
}

function verifyToken(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/verify-uid-token/`,
    data: selectionParams,
  });
}

function uploadImage(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/upload-id-proof/`,
    data: selectionParams,
  });
}

function uploadIDProofIAPI(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/upload-id-proof/`,
    data: selectionParams,
  });
}

function updateIDProofIAPI(selectionParams) {
  return axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/upload-id-proof/`,
    data: selectionParams,
  });
}

function registerProfile(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/edited-profile/`,
    data: selectionParams,
  });
}

// function getIndividualProfile() {
//   return axios.request({
//     method: "get",
//     url: `${BASE_URL}/api/user/edited-profile/`,
//   });
// }

function getsubscribe() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/email-subscribe/`,
  });
}

function updateProfile(selectionParams) {
  return axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/edited-profile/`,
    data: selectionParams,
  });
}

function getUserProfileApi(token) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/edited-profile/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function resetTokenRequest(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/token/`,
    data,
  });
}

function postKybRequest(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/kyb/`,
    data: selectionParams,
  });
}

function getKybRequest() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/kyb/`,
  });
}
function deleteKybRequest(files) {
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/kyb/`,
    data: files,
  });
}

function getKycRequest() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/upload-id-proof/`,
  });
}

function updateKybRequest(selectionParams) {
  return axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/kyb/`,
    data: selectionParams,
  });
}

function getDealList() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/investor-deal-details/`,
  });
}

function getMarketplaceListApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/marketplace-list/`,
  });
}
function getMAMarketplaceListApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/m-and-a-marketplace-list/`,
  });
}

function getServiceMarketplaceListApi(queryString) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/service-marketplace/?${queryString}`,
  });
}

function getOrganizerDealList() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/organizer-specific-deal-details/`,
  });
}

function getUserFlowApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/user-flow/`,
  });
}

function deleteDealRequest(data) {
  const formData = new FormData();
  formData.append("deal_id", data.deal_id);
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/delete-deal/`,
    data: formData,
  });
}

function getOrganizerDealDetails(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/project/getProject`,
    params,
  });
}

function getInvestorDealDetailsApi(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/investor-specific-deal-details/`,
    params,
  });
}

function getUserInsideLongevity() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/check-lp-user-flow/`,
  });
}

function getUserInsideInvestorRelation() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/check-irm-user-flow/`,
  });
}

function getUserDetailsByIDApi(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/users-details/`,
    params,
  });
}

function getDealInvesting(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/deal-investing/`,
    params,
  });
}

function getAllUserListApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/users-list/`,
  });
}

function dealInvest(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/deal-investing/`,
    data,
  });
}

function getEscrowDataAPI(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/create-transpact-escrow/`,
    params,
  });
}

function creatEscrowDataAPI(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/create-transpact-escrow/`,
    data,
  });
}

function deleteBankAcc() {
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/deal-investing/`,
  });
}

function getAdminDealList() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/admin-deals/?choice=all`,
  });
}

function adminDealUpdate(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/admin-deals/`,
    data: selectionParams,
  });
}

function getUserRole(token) {
  if (token) {
    return axios.request({
      method: "get",
      url: `${BASE_URL}/api/user/get-user-role/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/get-user-role/`,
  });
}

function registerCompanyProfile(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/company-profile/`,
    data: selectionParams,
  });
}

function getCompanyProfile() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/company-profile/`,
  });
}

function updateCompanyProfile(selectionParams) {
  return axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/company-profile/`,
    data: selectionParams,
  });
}

function questionnaireStatic(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/questionnaire-static/`,
    data: selectionParams,
  });
}

function updateQuestionnaireStatic(selectionParams) {
  return axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/questionnaire-static/`,
    data: selectionParams,
  });
}

function getQuestionnaireStatic() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/get-user-questionnaire/`,
  });
}

function sourceOfFund(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/source-of-funds/`,
    data: selectionParams,
  });
}

function getSourceOfFund() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/download-source-of-funds/`,
  });
}

function getSelectedServices() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/get-user-services/`,
  });
}

function updateSourceOfFund(data) {
  return axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/source-of-funds/`,
    data,
  });
}

function deleteSourceOfFund(data) {
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/source-of-funds/`,
    data,
  });
}

function getSourceOfFundsTemplateReq(selectionParams) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/source-of-funds/`,
    data: selectionParams,
  });
}

function getAccessTokenSumsubReq() {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/access-token-sumsub/`,
  });
}

function getApplicantActionSumsubReq() {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/applicant-action-sumsub/`,
  });
}

function getSumsubStatusReq() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/sumsub-status/`,
  });
}

function addIndividualBank(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/add-bank-account/`,
    data: selectionParams,
  });
}

function createDeal(token, dealData) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/project/addProject`,
    data: dealData,
  });
}
export function createDealV2(token, dealData) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/project/v2/create-projects`,
    data: dealData,
  });
}

function updateDeal(token, dealData) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/project/updateProject?id=${dealData.get("id")}`,
    data: dealData,
  });
}
export function publishDeal(token, dealData) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/project/updateProject?id=${dealData?.id}`,
    data: dealData,
  });
}

export function uploadTemplatesInstructions(files) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/project/add-templates`,
    data: files,
    headers:{
      type:"templates"
    }
  });
}
export function fetchTemplatesInstructions() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/project/fetch-templates`,
  });
}

export function deleteTemplatesInstructions(templateId) {
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/project/delete-template/${templateId}`,
  });
}


function downloadDocs(token) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/download-upload-docs/`,
  });
}

function uploadDocs(token, files) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/download-upload-docs/`,
    data: files,
  });
}

function signDoc(token, document) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/docusign_signature/`,
    data: document,
  });
}

function signInvestDoc(document) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/inv-doc-sign/ `,
    data: document,
  });
}

function publishDealApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/publish-deal/`,
    data,
  });
}

function unPublishDealApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/unpublish-deal/`,
    data,
  });
}

function unPublishIPApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/unpublish-ip/`,
    data,
  });
}

function addCommentApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/comment/`,
    data,
  });
}

function sectors() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/sector/`,
  });
}

function getNotifications() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/notifications/`,
  });
}

function deleteNotification(data) {
  const formData = new FormData();
  formData.append("notification_id", data);
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/notifications/`,
    data: formData,
  });
}

function getAdminInvestmentApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/admin-investment/`,
  });
}

function updateInvestmentStatusApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/admin-investment/`,
    data,
  });
}

function getConversationApi(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/conversation/`,
    params,
  });
}

function createChatApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/chat/`,
    data,
  });
}

function addUserToChatApi(data) {
  return axios.request({
    method: "patch",
    url: `${BASE_URL}/api/user/chat/`,
    data,
  });
}

function deleteUserFromChatApi(data) {
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/chat/`,
    data,
  });
}

function getAllMessagesApi(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/conversation/`,
    params,
  });
}

function getNewMessagesApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/chat-message/`,
    data,
  });
}

function sentMessageToEmail(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/chat-message/`,
    params,
  });
}

function getAllChatListApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/chat/`,
  });
}

function uploadDocumentApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/deal-details/`,
    data,
  });
}

function uploadAnyDocumentApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/user-upload-docs/`,
    data,
  });
}

function updateAnyDocumentApi(data) {
  return axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/user-upload-docs/`,
    data,
  });
}

function signAnyDocumentApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/additional-doc-sign/`,
    data,
  });
}

function uploadSignedAnyDocumentsApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/upload-signed-doc/`,
    data,
  });
}

function chengeAllowDocApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/doc-delete-flag/ `,
    data,
  });
}

function deleteAnyDocumentApi(data) {
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/user-upload-docs/`,
    data,
  });
}

function getUserDocumentsApi(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/user-upload-docs/`,
    params,
  });
}

function getDataRoomApi(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/data-room/`,
    params,
  });
}

function askPermissionApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/data-room/`,
    data,
  });
}

function getPermissionRequestApi(dealId) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/doc-permission/?deal_id=${dealId}`,
  });
}

function givePermissionRequestApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/doc-permission/`,
    data,
  });
}

function investorDetailsApi(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/inv-info/`,
    params,
  });
}

function getUserDetailsApi(token) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/get-user-details/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const getInvestorViewData = () =>
  axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/dashboard/investor-view/`,
  });
function getAdminTopOrganizersApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/admin-organizer/`,
  });
}

function updateAdminTopOrganizersApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/admin-organizer/`,
    data,
  });
}
function updateFacilitatorApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/admin-facilitator/`,
    data,
  });
}

const getUsersListApi = (params) =>
  axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/admin-page-users-list/`,
    params,
  });

const getUsersRolesApi = (params) =>
  axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/account-type/`,
    params,
  });

const updateUserRoleApi = (role) => {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/admin-page-users-role-change/`,
    data: role,
  });
};

const updateUserStatusApi = (status) => {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/admin-page-user-status-change/`,
    data: status,
  });
};

const getUserDetailByIdApi = (data) =>
  axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/admin-page-users-list/`,
    data,
  });

const updateUserData = (data) =>
  axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/admin-page-users-list/`,
    data,
  });
function dealTypeDetailsApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/deal-type-details/`,
    data,
  });
}

function serviceOrganizerDealsApi(data) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/service-org-deals/`,
  });
}

function updateServiceOrganizerDealStatusAPi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/service-org-deals/`,
    data,
  });
}

const getFacilitatorListApi = (params) =>
  axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/admin-facilitator/`,
    params,
  });

const getFacilitatorServicesApi = (params) =>
  axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/services/`,
    params,
  });

const setFacilitatorServicesApi = (data) =>
  axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/services/`,
    data,
  });

const updateFacilitatorServicesApi = (data) =>
  axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/services/`,
    data,
  });

function getAllGrantApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/grant-marketplace/`,
  });
}

function adminAddGrantApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/grant-marketplace/`,
    data,
  });
}

function adminEditGrantApi(data) {
  return axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/grant-marketplace/`,
    data,
  });
}

function adminDeleteGrantApi(data) {
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/grant-marketplace/`,
    data,
  });
}

function getAccountType(userID) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/account-type/?u_id=${userID}`,
  });
}
export function confirmEmail(token) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/auth/confirmEmail`,
    params: {
      token,
    },
  });
}

function dataRoomNotificationApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/data-room-notification/`,
    data,
  });
}

function createIp(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/ip/`,
    data,
  });
}

function editIp(data) {
  return axios.request({
    method: "put",
    url: `${BASE_URL}/api/user/ip/`,
    data,
  });
}

function getOrganizerIPDetails(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/ip/`,
    params,
  });
}

function getOrganizerIPList() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/ip-list/`,
  });
}

function deleteIPRequest(data) {
  const formData = new FormData();
  formData.append("ip_id", data);
  return axios.request({
    method: "delete",
    url: `${BASE_URL}/api/user/ip/`,
    data: formData,
  });
}

function getAdminIPs(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/admin-ip/`,
    params,
  });
}

function adminIPUpdateStatus(selectionParams) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/admin-ip/`,
    data: selectionParams,
  });
}

function getIPMarketplaceListApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/ip-marketplace/`,
  });
}

function getIPInvest(id) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/ip-buy/?id=${id}`,
  });
}

function createInvest(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/ip-buy/`,
    data,
  });
}

function publishIPApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/publish-ip/`,
    data,
  });
}
function getIpDetailsApi(id) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/ip/?id=${id}`,
  });
}

function getIPDetailPageDetails(id) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/org-ip-details/?ip_id=${id}`,
  });
}

function getIPDetailInvestorPageDetails(id) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/inv-ip-details/?id=${id}`,
  });
}

function getIpDataRoomApi(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/ip-data-room/`,
    params,
  });
}

function addIpCommentApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/comment/`,
    data,
  });
}
function getAdminIPInvestmentApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/admin-ip-buy/`,
  });
}

function updateIPInvestmentStatusApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/admin-ip-buy/`,
    data,
  });
}
function getEmailsForIRM() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/irm-email/`,
  });
}
function sendEmailsForIRM(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/irm-email/`,
    data,
  });
}

function getIpPermissionRequestApi(ipId) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/ip-doc-permissions/?ip_id=${ipId}`,
  });
}

function giveIpPermissionRequestApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/ip-doc-permissions/`,
    data,
  });
}

// function dataRoomIpNotificationApi(data) {
//   return axios.request({
//     method: "post",
//     url: `${BASE_URL}/api/user/data-room-notification/`,
//     data,
//   });
// }

function askIpDocDownloadPermissionApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/ip-data-room/`,
    data,
  });
}

function askIpPermissionApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/ip-data-room/`,
    data,
  });
}

function ipDataRoomNotificationApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/api/user/ip-data-room-notification/`,
    data,
  });
}

function socialLoginApi() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/social-login/`,
  });
}

function socialVerifyingApi(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/auth/login`,
    data,
  });
}

function getDealAllTitles() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/deal-titles/`,
  });
}

function getIPAllTitles(type) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/api/user/ip-titles/?type=${type}`,
  });
}

export function getProjects() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/project/getAllProjects`,
  });
}
export function getProjectsListV2(params) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/project/v2/listProjects`, //  ONLY diffeerence from getProjects() it retrieves less fields
    params,
  });
}
export function getProjectsOverView() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/project/projects-summary`,
  });
}

export function getMyProjects() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/project/getMyProjects`,
  });
}

export function deleteProjectTableData(id) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/project/deleteProject?id=${id}`,
  });
}

export function archiveProjectTableData(id) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/project/achivedProject?id=${id}`,
  });
}

export function getUsersData(id) {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/admin/getUsers`,
  });
}
export function updateUsersData(data) {
  return axios.request({
    method: "post",
    url: `${BASE_URL}/user/changeUser`,
    data,
  });
}

export function getAllProjects() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/admin/getAllProjects`,
  });
}

export function getAllUsers() {
  return axios.request({
    method: "get",
    url: `${BASE_URL}/user/getUserList`,
  });
}

export function checkUserProjectDataAccess(query){
  return axios.request({
    method: "get",
    url: `${BASE_URL}/data-room/check-access`,  // projectId=24924&userId=123
    params: query
  })
}

export function requestAccessToProjectFiles(data){ // { "userId": "", "projectId": "", "accessType": str:['project', 'data_room', 'extended_data_room'] }
  // Used to request access to project or project files by specifiying request Type
  return axios.request({
    method: "post",
    url: `${BASE_URL}/data-room/request-access`, 
    data
  })
}
export function fetchAccessRequests(params){ // ?accessTypeQuery=str:['project', 'data_room', 'extended_data_room']
  return axios.request({
    method: "get",
    url: `${BASE_URL}/data-room/list-requests`, 
    params,
  })
}
export function manageUserProjectAccess(data){ // { "requestId" : "", "requestStatus" : "approved/rejected/pending", "adminId" : "" }
  return axios.request({
    method: "post",
    url: `${BASE_URL}/data-room/approve-request`, 
    data
  })
}
export function createAccessByAdmin(data){ // { userId, projectId, status, adminId, email, accessType }
  return axios.request({
    method: "post",
    url: `${BASE_URL}/data-room/grant-user-access`, 
    data
  })
}

export function checkProjectAccess(query){
  return axios.request({ // access to project ONLY not DATE ROOM
    method: "get",
    url: `${BASE_URL}/data-room/check-project-access`,  // projectId=24924&userId=123
    params: query
  })
}
export function createPaymentIntent(data){
  return axios.request({ 
    method: "post",
    url: `${BASE_URL}/payments/createPayment`, 
    data
  })
}


export function makeInvestProjectRequest(data){ // data : {projectId, message, investorId}
  return axios.request({
    method: "post",
    url: `${BASE_URL}/investors/invest-request`, 
    data
  })
}
export function checkUserMadeInvestRequest(data){ // /invest-request-made/:projectId/:investorId
  return axios.request({
    method: "get",
    url: `${BASE_URL}/investors/invest-request-made/${data?.projectId}/${data?.investorId}`, 
  })
}
export function listInvestProjectRequests(projectId){ 
  return axios.request({
    method: "get",
    url: `${BASE_URL}/investors/project-requests/${projectId}`, 
  })
}

export function manageInvestRequest({requestID, status}){ // ['pending', 'approved', 'rejected']
  return axios.request({
    method: "patch",
    url: `${BASE_URL}/investors/manage-request/${requestID}`, 
    data:{
      status
    }
  })
}




export {
  api,
  signUp,
  signIn,
  selectAccount,
  addinvestorType,
  forgotPassword,
  verifyForgotPassword,
  changePassword,
  getsubscribe,
  postsubscribe,
  resendSignupVerify,
  getAllMessagesApi,
  getNewMessagesApi,
  uploadAnyDocumentApi,
  getEscrowDataAPI,
  creatEscrowDataAPI,
  getUserDocumentsApi,
  verifyToken,
  getDealList,
  getUserRole,
  addUserToChatApi,
  updateFacilitatorServicesApi,
  uploadImage,
  registerProfile,
  postKybRequest,
  createChatApi,
  uploadSignedAnyDocumentsApi,
  getConversationApi,
  getUserDetailsApi,
  registerCompanyProfile,
  questionnaireStatic,
  getAllUserListApi,
  updateIDProofIAPI,
  getAllChatListApi,
  deleteUserFromChatApi,
  updateSourceOfFund,
  deleteSourceOfFund,
  sourceOfFund,
  addBankAccount,
  getSourceOfFundsTemplateReq,
  addIndividualBank,
  createDeal,
  downloadDocs,
  uploadDocs,
  getAccessTokenSumsubReq,
  getApplicantActionSumsubReq,
  signDoc,
  getUserDetailsByIDApi,
  sectors,
  uploadDocumentApi,
  getUserProfileApi,
  updateDeal,
  signAnyDocumentApi,
  getKycRequest,
  getSourceOfFund,
  getAdminDealList,
  adminDealUpdate,
  signInvestDoc,
  getOrganizerDealList,
  getOrganizerDealDetails,
  uploadIDProofIAPI,
  getDataRoomApi,
  getDealInvesting,
  getInvestorDealDetailsApi,
  updateInvestmentStatusApi,
  dealInvest,
  deleteBankAcc,
  updateKybRequest,
  deleteDealRequest,
  publishDealApi,
  addCommentApi,
  askPermissionApi,
  givePermissionRequestApi,
  getPermissionRequestApi,
  getMarketplaceListApi,
  getServiceMarketplaceListApi,
  getNotifications,
  getAdminInvestmentApi,
  getKybRequest,
  deleteNotification,
  // getIndividualProfile,
  updateProfile,
  chengeAllowDocApi,
  deleteAnyDocumentApi,
  resetTokenRequest,
  sentMessageToEmail,
  investorDetailsApi,
  getCompanyProfile,
  updateCompanyProfile,
  getCurrentPassword,
  getInvestorViewData,
  getAdminTopOrganizersApi,
  updateAdminTopOrganizersApi,
  getUsersListApi,
  updateQuestionnaireStatic,
  getSelectedServices,
  getUsersRolesApi,
  updateUserRoleApi,
  updateUserStatusApi,
  getUserDetailByIdApi,
  updateUserData,
  getQuestionnaireStatic,
  dealTypeDetailsApi,
  serviceOrganizerDealsApi,
  updateServiceOrganizerDealStatusAPi,
  getFacilitatorListApi,
  getFacilitatorServicesApi,
  setFacilitatorServicesApi,
  getSumsubStatusReq,
  getMAMarketplaceListApi,
  updateFacilitatorApi,
  updateAnyDocumentApi,
  getAllGrantApi,
  adminAddGrantApi,
  adminDeleteGrantApi,
  adminEditGrantApi,
  getAccountType,
  dataRoomNotificationApi,
  createIp,
  editIp,
  getOrganizerIPList,
  getOrganizerIPDetails,
  getAdminIPs,
  adminIPUpdateStatus,
  getIPMarketplaceListApi,
  deleteIPRequest,
  deleteKybRequest,
  getIPInvest,
  createInvest,
  publishIPApi,
  getIpDetailsApi,
  getIpDataRoomApi,
  addIpCommentApi,
  getAdminIPInvestmentApi,
  updateIPInvestmentStatusApi,
  getEmailsForIRM,
  sendEmailsForIRM,
  deleteDocs,
  getIpPermissionRequestApi,
  giveIpPermissionRequestApi,
  askIpPermissionApi,
  longevityLogin,
  getIPDetailPageDetails,
  getIPDetailInvestorPageDetails,
  ipDataRoomNotificationApi,
  unPublishDealApi,
  unPublishIPApi,
  getUserFlowApi,
  socialLoginApi,
  socialVerifyingApi,
  getDealAllTitles,
  getIPAllTitles,
  globalSearchByName,
  getUserInsideLongevity,
  getUserInsideInvestorRelation,
  getProject,
  updateUserApi,
  getUserApi,
  updatePasswordApi,
};
