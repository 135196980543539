import {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_ERROR,
    DELETE_NOTIFICATIONS_REQUEST,
    DELETE_NOTIFICATIONS_SUCCESS,
    DELETE_NOTIFICATIONS_ERROR,
    FILTER_SECTOR,
} from "./constants";
  
const initialState = {
    loading: false,
    notificationsList: null,
    dataLength: null,
    sectorName: 'All Projects'
};
  
export default function notificationsData(state = initialState, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS_REQUEST:
            return { ...state, loading: true };
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notificationsList: action.data,
                dataLength: action.data.length,
                loading: false,
            };
        case GET_NOTIFICATIONS_ERROR:
            return { ...state, loading: false };

        case DELETE_NOTIFICATIONS_REQUEST:
            return { ...state, loading: true };
        case DELETE_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notificationsList: action.data,
                dataLength: action.data.length,
                loading: false,
            };
        case DELETE_NOTIFICATIONS_ERROR:
            return { ...state, loading: false };
        case FILTER_SECTOR:
                return { ...state, sectorName: action.data, loading: false };
        default:
            return state;
    }
}