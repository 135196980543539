
import React, { useEffect, useState } from "react";
import {
    Grid,
    Tag,
    message as antdMessage,
    notification,
} from "antd";


import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { BASE_URL, checkProjectAccess, checkUserMadeInvestRequest, checkUserProjectDataAccess, makeInvestProjectRequest, requestAccessToProjectFiles } from "../../../api/index";
import { getUser } from "../../../Utils/isAuth";

const colors = {
    "Tourism": "green",
    "Social Media": "cyan",
    "Software": "magenta",
    "Manufacturing": "volcano",
    "Construction & Real Estate": "orange",
    "eGovernment": "blue",
    "Automotive": "geekblue",
    "Research & Development": "purple",
    "Health & Medical": "purple",
    "Transport": "magenta",
    "Computer Equipment Services": "green",
    "Entertainment": "lime",
    "Media": "volcano",
    "Education": "cyan",
    "Finance & Banking": "gold",
    "E-Commerce & Retail": "lime",
    "Telecommunications": "green",
    "Philanthropy and Impact Investment": "blue",
    "DeepTech and AI": "orange",
    "Life Sciences and HealthTech": "volcano",
};


const useDealHeader = (DealInfo, userRole) => {
    const dispatch = useDispatch();
    const [ investRequestObj, setInvestRequestObj ] = useState({})
    const [ showModal, setShowModal ] = useState(false)   
    const [userHasFilesAccess, setUserHasFilesAccess] = useState({});
    const [textMessage, setTextMessage] = useState("");
    const [checkTrigger, setCheckTrigger] = useState(0);  // Initial state set to 0
    const [loading, setLoading] = useState(false);
    const { _id: userId, email: userEmail, roles } = getUser();
    const sector = DealInfo?.sector;
    const logo = DealInfo?.links?.find((item) => item.type === "logo")?.link || "";
    const projectName = DealInfo?.projectName;
    const websiteLink = DealInfo?.websiteLink;
    const description = DealInfo?.description;
    const goalAmount = DealInfo?.goalAmount;
    const projectId = DealInfo?._id
    const navigate = useNavigate();


    const handleRequestAccess = async (accessType) => {
        try {
            setLoading(true)
            const data = { projectId, userId, accessType }
            const resp = await requestAccessToProjectFiles(data)
            if (resp?.status === 201) {
                notification.success({
                    message: resp?.data?.message
                })
            } else {
                notification.error({
                    message: resp?.data?.message
                })
            }
        } catch (err) {
            notification.error({
                message: err?.message
            })
        } finally {
            setLoading(false)
        }
        setCheckTrigger(prev => prev + 1);  // Simple trigger to force component to remount in order to change access message accordignly

    }

    const userHasAccessProject = () => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@dkv\.global$/; // user email must match @dkv.global
        return ((userRole === "Admin" || userRole === "Partner" || emailPattern.test(userEmail)) || DealInfo?.owner?._id === userId)
    }


    const userRequestedInvest = async () => {
        try{
            const response = await checkUserMadeInvestRequest({projectId, investorId:userId})
            setInvestRequestObj(response?.data?.requestExists)
        }catch(error){
            console.log("error: ", error)
        }finally{
            console.log("done: ")
        }
    }

    const requestInvesting = async () => {
        try{
            setLoading(true)
            const response = await makeInvestProjectRequest({
                message:textMessage,
                investorId:userId,
                projectId:projectId
            })
            antdMessage.success(response?.data?.message)
            setShowModal(false)
        }catch(error){
            antdMessage.error(error?.response?.data?.message)
        }finally{
            userRequestedInvest()
            setLoading(false)
        }
    }

    


    useEffect(() => {
        if ( roles === "Investor" ){
            userRequestedInvest()
        }
        if (projectId && userId && !userHasAccessProject()) { // make request only if user is not Admin or not Partner or email is not dkv
            // first check if user has access to the entire project
            const userProjectDetailsAccess = async () => {
                try {
                    const projectAccess = await checkProjectAccess({ userId, projectId })
                } catch (err) {
                    if (err?.response?.status === 403) { // user does not have access to project
                        notification.error({
                            message: "You do not have right to access this page"
                        })
                        return navigate("/")
                    }
                    notification.error({
                        message: err?.message
                    })
                }
            }


            const getUserFileAccess = async () => {
                try {
                    const accessData = await checkUserProjectDataAccess({ userId, projectId })
                    if (accessData?.status === 200) {
                        setUserHasFilesAccess(accessData?.data?.access)
                    }
                } catch (err) {
                    notification.error({
                        message: err?.message
                    })
                }
            }
            userProjectDetailsAccess()
            getUserFileAccess()
        }
    }, [userId, projectId, checkTrigger])


    const SectorTags = () => {
        let sectors;

        try {
            sectors = JSON.parse(sector);
        } catch (error) {
            // Parsing failed, treat sector as a single string
            sectors = [sector];
        }
        return sectors.map((sector, index) => (
            <span className="top-title_sector" key={sector}>
                <Tag color={colors[sector]}>
                    {sector === "Investable Projects"
                        ? sector
                        : sector?.split("Projects")[0]}
                </Tag>
            </span>
        ));
    };


    const getImageLink = (imagesPath, link) => {
        return BASE_URL + imagesPath + link;
    };
    return {
        handleRequestAccess,
        userHasAccessProject,
        SectorTags,
        getImageLink,
        userHasFilesAccess,
        loading, 
        logo,
        projectName,
        websiteLink,
        description,
        userEmail,
        goalAmount,
        requestInvesting,
        setTextMessage,
        setShowModal,
        showModal,
        investRequestObj
    }
}

export default useDealHeader