import {
    GET_NOTIFICATIONS_REQUEST,
    DELETE_NOTIFICATIONS_REQUEST,
    FILTER_SECTOR
} from "./constants";

export const getNotificationsRequest = () => {
    return {
      type: GET_NOTIFICATIONS_REQUEST,
    };
};

export const deleteNotificationsRequest = (data) => {
    return {
      type: DELETE_NOTIFICATIONS_REQUEST,
      data,
    };
};

export const addSectorFilter = (data) =>  {
  return {
    type: FILTER_SECTOR,
    data,
  };
}