import { Modal, Spin } from "antd";
import React from "react";
import DealDetailHeader from "./components/DealDetailHeader";
import DealDetailIntro from "./components/DealDetailIntro";
import InvestorDealDetail from "./InvestorDealDetail";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";

import "./style.scss";
import AdminDealDetail from "./AdminDealDetail";
import DocumentsList from "./tabs/dataRoom/DocumentsList";
import useManageDealDetail from "./useManageDealDetail";
import IframeComponent from "./components/IframeComponent";


const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const { error } = Modal;



function DealDetails() {
  const allowedRoles = [ "Admin", "Partner", "Organisation" ]
  const {
    dealDetails,
    loading,
    userRole,
    user,
    projectData,
    displayDataRoom,
    setDisplayDataRoom,
    showProjectDataFiles,
    setShowProjectDataFiles,
    dataRoomLink,
  } = useManageDealDetail()
  
  return (
    dealDetails && (
      <div className="deal-detail">
        {loading && (
          <div className="spinner_background">
            <Spin className="spinner" indicator={antIcon} />
          </div>
        )}
          <>
            <DealDetailHeader
              projectData={projectData?.project}
              userRole={userRole}
              setDisplayDataRoom={setDisplayDataRoom}
              displayDataRoom={displayDataRoom}
              setShowProjectDataFiles={setShowProjectDataFiles}
              showProjectDataFiles={showProjectDataFiles}
            />
            {showProjectDataFiles === "data_room" ? (
              <>
                {dataRoomLink && (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="primary"
                      onClick={() => window.open(dataRoomLink, "_blank")}
                    >
                      Go To Data Room
                    </Button>
                  </div>
                )}

                <DocumentsList
                  documents={
                    projectData?.project?.links?.filter(
                      (item) => item?.type === "dataRoomDocs" && !item?.isHidden
                    ) || []
                  }
                  imagesPath={projectData?.project?.imagesPath}
                  videoLink={projectData?.project?.videoLink}
                  projectName={projectData?.project?.projectName}
                  dealId={123}
                />
              </>
            ) : showProjectDataFiles === "extended_data_room" ? (
              <DocumentsList
                  documents={
                    projectData?.project?.links?.filter(
                      (item) => item?.type === "extended_data_room" && !item?.isHidden
                    ) || []
                  }
                  imagesPath={projectData?.project?.imagesPath}
                  videoLink={projectData?.project?.videoLink}
                  projectName={projectData?.project?.projectName}
                  dealId={123}
                />
            ) : showProjectDataFiles === "pricing_documents" ? (
              <DocumentsList
                  documents={
                    projectData?.project?.links?.filter(
                      (item) => item?.type === "pricing_documents" && !item?.isHidden
                    ) || []
                  }
                  imagesPath={projectData?.project?.imagesPath}
                  videoLink={projectData?.project?.videoLink}
                  projectName={projectData?.project?.projectName}
                  dealId={123}
                />
            )
            : projectData?.project?.company_website ? (
              <IframeComponent title={"DocumentCreatorIftame"} source={projectData?.project?.company_website} />
            ) : (
              ""
            )}
          </>

        {user.is_superuser && dealDetails && (
          <>
            <DealDetailHeader
              dealDetail={dealDetails?.deal_info}
              userRole={"Admin"}
            />
            <DealDetailIntro
              dealDetail={dealDetails?.deal_info}
              userRole={userRole}
            />
            <AdminDealDetail dealDetail={dealDetails} userRole={userRole} />
          </>
        )}
        {(userRole === "INV" || userRole === "FAC") && !user.is_superuser && (
          <>
            <DealDetailHeader dealDetail={dealDetails} userRole={userRole} />
            <DealDetailIntro dealDetail={dealDetails} userRole={userRole} />
            <InvestorDealDetail dealDetail={dealDetails} userRole={userRole} />
          </>
        )}
      </div>
    )
  );
}

export default DealDetails;
