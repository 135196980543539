import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { canCreateProjects, getUser } from "../Utils/isAuth";

const PrivateRoute = ({ children }) => {
  const user = getUser(); 
  const location = useLocation();
  const { pathname } = useLocation()

  if (!user) {
    // Redirect to login if user is not authenticated
    return <Navigate to="/login" />;
  }

  // Check roles and handle redirections
  if (user?.roles === "Basic" && pathname !== "/select-role") {
    return <Navigate to="/select-role" />;
  }

  if (user?.roles !== "Admin" && location.pathname.includes("user-listing")) {
    return <Navigate to="/" />;
  }

  // Render children if all conditions pass
  return children;
};


export function AdminOnly({ children }) {
  const user = getUser();

  if (
    user &&
    (user?.roles !== "Admin" || !user?.email?.includes("dkv.global") )
  ) {
    return <Navigate to="/" />;
  }
  
  return user ? children : <Navigate to="/login" />;
}





export function CreateProjectPermission({children}){
  return canCreateProjects() ? children : <Navigate to="/" />
}

export default PrivateRoute;
