import {
  Avatar,
  Button,
  Col,
  Grid,
  Image,
  Modal,
  Row,
  Typography,
} from "antd";

import {
  EditFilled,
  ClockCircleOutlined
} from '@ant-design/icons';

import { Link } from "react-router-dom";

import FallbackImg from "../../../assets/images/default-fallback-image.png";
import useDealHeader from "./useDealHeader";
import AccessButton from "./AccessButtons";
import { isInvestor, isProjectOwner } from "../../../Utils/isAuth";
import { useState } from "react";
import TextArea from "antd/lib/input/TextArea";

const { Title, Paragraph } = Typography;

function DealDetailHeader({
  projectData = {},
  userRole,
  displayDataRoom,
  setDisplayDataRoom,
  setShowProjectDataFiles,
  showProjectDataFiles
}) {
  const {
    handleRequestAccess,
    userHasAccessProject,
    SectorTags,
    getImageLink,
    userHasFilesAccess,
    loading,
    logo,
    projectName,
    websiteLink,
    description,
    userEmail,
    setTextMessage,
    requestInvesting,
    showModal, 
    setShowModal,
    investRequestObj,
    goalAmount
  } = useDealHeader(projectData, userRole)
  const screens = Grid.useBreakpoint();


  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        gutter={[0, 0]}
        className="deal-details-header-row"
      >
        <Col md={18} sm={24} xs={24}>
          <Avatar.Group>
            <div className={`deal-logo`}>
              <Image
                width={60}
                src={
                  logo
                    ? getImageLink(projectData?.imagesPath, logo)
                    : FallbackImg
                }
                style={{
                  marginRight: "15px",
                }}
              />
            </div>

            <div style={{ width: "100%" }}>
              <Row align="middle" gutter={[0, 0]}>
                <Col
                  style={{
                    flexWrap: "wrap",
                  }}
                  md={24}
                  sm={24}
                  display={"flex"}
                  className="top-title"
                >
                  <Title
                    level={4}
                  >
                    {projectName}
                    {
                      (userRole === "Admin" || userEmail?.includes("dkv.global") || isProjectOwner(projectData)) &&
                      <Link to={`/edit-projects/${projectData?._id}`}><EditFilled /></Link>
                    }
                  </Title>
                  <div>
                    <SectorTags />
                  </div>
                </Col>
              </Row>
            </div>
          </Avatar.Group>
          <Paragraph
            ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
            style={{ color: "#6b7280", marginTop: "5px", maxWidth:"100%" }}
          >
            {description}
          </Paragraph>
        </Col>
        <Col
          md={6}
          sm={24}
          xs={24}
          style={{
            display: "flex",
            justifyContent: `${screens.md ? "flex-end" : "center"}`,
            marginTop: `${screens.xs ? "5px" : ""}`,
          }}
        >
          <div style={{
            display: "flex",
            alignItems: "",
            gap: "1rem",
            flexDirection: "column",
            marginBottom:"1rem",
            width: `${ screens.md ? "fit-content" : "100%" }`
          }}>
            {
              goalAmount &&
            <div>
            <span>Price</span>: { Number(goalAmount).toLocaleString("en-US", {style:"currency", currency:"USD"})}
            </div>
            }
            <AccessButton
              accessType="data_room"
              userHasFilesAccess={userHasFilesAccess}
              userHasAccessProject={userHasAccessProject}
              showProjectDataFiles={showProjectDataFiles}
              setShowProjectDataFiles={setShowProjectDataFiles}
              handleRequestAccess={handleRequestAccess}
              loading={loading}
            />
            <AccessButton
              accessType="extended_data_room"
              userHasFilesAccess={userHasFilesAccess}
              userHasAccessProject={userHasAccessProject}
              showProjectDataFiles={showProjectDataFiles}
              setShowProjectDataFiles={setShowProjectDataFiles}
              handleRequestAccess={handleRequestAccess}
              loading={loading}
            />
            {
            userHasAccessProject() && (
              //button "Prices". should be visible for Admin and for Partner
            <Button type="primary" 
            onClick={() => setShowProjectDataFiles((prev) => prev === "pricing_documents" ? null : "pricing_documents")}>
              { showProjectDataFiles === "pricing_documents" ? "Hide" : "Show" } Pricing
            </Button>
            )
            }
            {
            isInvestor() && 
            !investRequestObj ?
            <Button type="primary" onClick={()=> setShowModal(!showModal)}>
                Send Invest Request
            </Button> :
            investRequestObj?.status === "pending" ?
            <p><ClockCircleOutlined /> Your investment request is awaiting review.</p> :
            investRequestObj?.status === "rejected" ? 
            <p>Unfortunately, your investment request has been declined.</p> : 
            investRequestObj?.status === "approved" ?
            <p style={{ color: "green" }}>Your investment request has been approved.</p> : null
            }
            
          </div>
        </Col>
        {websiteLink && (
          <Button
            type="primary"
            onClick={() => window.open(websiteLink, "_blank")}
          >
            Go To Website
          </Button>
        )}
      </Row>

      <Modal
        title="Send Invest Request"
        open={showModal}
        onOk={requestInvesting}
        confirmLoading={loading}
        onCancel={()=> setShowModal(!showModal)}
      >
                    <div>
                        <p style={{ marginBottom: "1rem", color:"gray" }}>
                          Ensure to provide a comprehensive amount of information, covering all relevant aspects, so that the project owner has a thorough understanding and can make a well-informed decision based on the provided details.
                        </p>
                        <Title level={5} style={{ margin: 0 }}>Message:</Title>
                        <TextArea
                          rows={6}
                          placeholder="Provide detailed information here..."
                          onChange={(e)=> setTextMessage(e.target.value)}
                        />
                        <p style={{color:"gray", fontSize:"0.7rem"}}>
                        The project owner will have access to your full name, email, message, and phone number.</p>
                    </div>
      </Modal>
    </>
  );
}

export default DealDetailHeader;
