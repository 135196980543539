import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Drawer, Menu, Dropdown, Avatar, Tooltip, Collapse } from "antd";
import Icon, { ShoppingOutlined } from "@ant-design/icons";
import Logo from "../../../assets/images/main-logo-svg.svg";
import "./index.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { MenuSvg } from "../../../assets/images/menu";
import {
  getUser,
  removeLongevityMail,
  removeUserSession,
} from "../../../Utils/isAuth";
import { useDispatch, useSelector } from "react-redux";
import { MoreOutlined } from "@ant-design/icons";

import { addSectorFilter } from "../../../layout/dashboard/redux/action";
import { getFirstLater, getImageURL } from "../../../Utils";
import { TooltipContents } from "../../../Utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import SidenavHandler from "../../../layout/dashboard/SidenavHandler";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

function MobileHeader() {
  useEffect(() => window.scrollTo(0, 0));
  const user = getUser();

  const [visible, setVisible] = useState(false);
  const refreshPage = useSelector((state) => state.auth.refreshPage);
  const chatList = useSelector((state) => state.chat.chatList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setFake] = useState(false);

  const [key1, setKey1] = useState("0");
  const { sideNavItems, navBarOptions } = SidenavHandler();

  const { logout } = useAuth0();

  useEffect(() => {
    setFake(refreshPage);
  }, [refreshPage]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleMenuClose = (e) => {
    if (e.key) {
      setVisible(false);
    }
  };

  const handleSidebarClick = (title, key) => {
    setKey1(key);
    dispatch(addSectorFilter(title));
    setVisible(false);
  };

  const signOutMenu = (
    <Menu className="edit-menu" style={{ zIndex: 100000000 }}>
      {user?.roles === "Admin" && (
        <Menu.Item
          onClick={() => navigate("/user-listing")}
          className="edit-menu__item"
          key="edit-menu-item-3"
        >
          <p>User Administration</p>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={(event) => {
          navigate("/my-projects");
        }}
        className="edit-menu__item"
        key="edit-menu-item-1"
      >
        <p>My Projects</p>
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          navigate("/settings");
        }}
        className="edit-menu__item"
        key="edit-menu-item-1"
      >
        <p>Settings</p>
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          window.open("https://docs.google.com/forms/d/e/1FAIpQLSfw4oHHcBXZx0Fm7vpE9FpD36ILaGtWTRnzBEhuYnAYDj0RxQ/viewform?usp=sf_link", "_blank")
        }}
        className="edit-menu__item"
        key="edit-menu-item-6"
      >
        <p>Submit Feedback</p>
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          removeUserSession();
          removeLongevityMail();
          logout();
        }}
        className="edit-menu__item"
        key="edit-menu-item-2"
      >
        <p>Sign Out</p>
      </Menu.Item>
    </Menu>
  );

  const isUndeaded = useMemo(() => {
    let hasUnreaded = false;
    chatList.forEach((el) => {
      if (el.message_count > 0) {
        hasUnreaded = true;
      }
    });
    return hasUnreaded;
  }, [chatList]);

  const SideMenuItem = ({sidebarItem}) => (
    sidebarItem.map(
        ({
          checkUserRole,
          icon,
          title,
          path,
          key,
          description,
        }) => {
          return (
            checkUserRole && (user?.categories?.[0] === "all" || user?.categories?.includes(title)) && (
              <Menu.Item
                key={key}
                onClick={() => {
                  handleSidebarClick(title, key);
                }}
                className={`side-menu-item ${
                  key1 === key && "ant-menu-item-selected"
                }`}
              >
                <Tooltip
                  placement="right"
                  mouseLeaveDelay={0}
                  title={TooltipContents[description]}
                >
                  <NavLink to={path}>
                    <span className="label">{title}</span>
                  </NavLink>
                </Tooltip>
              </Menu.Item>
            )
          );
        }
      )
  )

  return (
    <>
      <Row
        gutter={[8, 8]}
        align="middle"
        wrap={false}
        style={{ display: "flex" }}
      >
        <Col span={8}>
          <Icon component={MenuSvg} onClick={showDrawer} />
        </Col>
        <Col span={8}>
          <div
            className="header-brand"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={Logo} alt="Logo" />
          </div>
        </Col>
        <Col span={8} className="header-control" style={{ color: "red" }}></Col>
      </Row>

      <Drawer
        title={
          <div
            className="brand"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              onClick={() => navigate("/")}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        }
        placement={"left"}
        closable={true}
        onClose={onClose}
        visible={visible}
        key={"mobile-nav"}
        width="320px"
        className="mobile-nav"
      >
        <div className="sider-primary ant-layout-sider-primary">
          <Menu theme="light" mode="inline" onClick={(e) => handleMenuClose(e)}>
          <Collapse
              expandIconPosition="end"
              ghost
              defaultActiveKey={[1, 2, 3]}
            >
              {Object.entries(navBarOptions).map(([header, sidebarItem], index) => (
                <>
                  {sidebarItem.length > 1 ? (
                    <CollapsePanel
                      className="sidepanel-header"
                      header={header}
                      key={index}
                    >
                      <SideMenuItem sidebarItem={sidebarItem} />
                    </CollapsePanel>
                  ) : (
                    <SideMenuItem sidebarItem={sidebarItem} />
                  )}
                </>
              ))}
            </Collapse>

            {/* {sideNavItems.map(
              ({ checkUserRole, icon, title, path, key, description }) => {
                return (
                  checkUserRole && (
                    <Menu.Item
                      key={key}
                      onClick={() => {
                        handleSidebarClick(title, key);
                      }}
                      className={`side-menu-item ${
                        key1 === key && "ant-menu-item-selected"
                      }`}
                    >
                      <Tooltip
                        placement="right"
                        mouseLeaveDelay={0}
                        title={TooltipContents[description]}
                      >
                        <NavLink to={path}>
                          {key === "15" ? (
                            <ShoppingOutlined
                              style={{ fontSize: "32px" }}
                              className={`icon-nav icon-nav-longevity`}
                            />
                          ) : (
                            <Icon
                              component={icon}
                              style={{ fontSize: "32px" }}
                              className={`icon-nav ${
                                key === "6" && isUndeaded ? "unreaded" : ""
                              }`}
                            />
                          )}
                          <span className="label">{title}</span>
                        </NavLink>
                      </Tooltip>
                    </Menu.Item>
                  )
                );
              }
            )} */}
          </Menu>
          <div id="user_sidebar_button">
            <hr />
            <Row gutter={[5]}>
              <Col sm={4}>
                <div className="user_sidenav">
                  <Avatar
                    className="user_sidenav"
                    src={getImageURL(user?.userLogo || "")}
                  >
                    {getFirstLater(user?.email || "")}
                  </Avatar>
                </div>
              </Col>
              <Col sm={17}>
                <div className="username_sidenav">
                  <h3 className="username_sidenav">{`${user?.email}`}</h3>
                </div>
              </Col>

              <Col sm={2} className="sign-out-more">
                <Dropdown
                  overlay={signOutMenu}
                  trigger="click"
                  placement="topRight"
                >
                  <MoreOutlined />
                </Dropdown>
              </Col>
              <Col sm={1}></Col>
            </Row>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default MobileHeader;
